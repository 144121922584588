*{
  padding: 0;
  margin: 0;
  font-family:  'K2D', sans-serif;
}
.App{
  background: #000;
  min-height: 100vh;
}



.avatar-hover-effect{
  display: none;
}
.avatar-hover:hover .avatar-hover-effect{
  display: block !important;
}